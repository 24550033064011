@import 'breakpoints';

.container.rooms {
    .error-loading-msg {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .rooms-grid-wrapper {
        --roomCardWidth: 500px;
        --roomCardPictureHeight: 260px;
        --roomCardDefaultHeight: 300px;
        @media screen and (max-width: 550px) {
            --roomCardWidth: 85vw;
        }
        display: grid;
        grid-template-columns: repeat(auto-fit, var(--roomCardWidth));
        grid-template-rows: auto;
        grid-gap: 1.5rem;
        justify-content: center;
        padding: 0 4rem;
        @include notDesktop {
            padding: 0;
        }
        margin-top: 2rem;
        margin-bottom: 5rem;
        .room-card {
            width: var(--roomCardWidth);
            background-color: var(--BookingMain);
            border-radius: 8px;
            padding: 1rem 1.2rem;
            box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.2);
            .loading, .error {
                height: var(--roomCardDefaultHeight);
                width: 100%;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                img {
                    height: 100px;
                }
                img, .sad-face {
                    margin-top: -1.5rem;
                }
                .sad-face {
                    font-size: 4rem;
                    font-weight: 200;
                    margin-bottom: 0.75rem;
                }
            }
            &__picture {
                position: relative;
                width: 100%;
                height: var(--roomCardPictureHeight);
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                overflow: hidden;
                .room-card__image {
                    position: absolute;
                    top: 0; left: 0; right: 0; bottom: 0;
                    z-index: 1;
                    transform: translateX(101%);
                    transition: transform 300ms cubic-bezier(0.59, 0, 0.06, 1);
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    &.active {
                        transform: translateX(0);
                    }
                    &.passed {
                        transform: translateX(-101%);
                    }
                }
                .overlay {
                    position: absolute;
                    top:0;left:0;right:0;bottom:0;
                    z-index: 2;
                    overflow: hidden;
                    padding: 0 0.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    &:hover {
                        .btn.next, .btn.previous {
                            transform: translateX(0)
                        }
                        .counter {
                            opacity: 1;
                        }
                    }
                    .counter {
                        position: absolute;
                        bottom: 0.5rem;
                        right: 0.8rem;
                        opacity: 0; visibility: visible;
                        transition: opacity 300ms ease;
						background-color: var(--BookingMain);
						padding: 5px 8px;
						border-radius: 6px;
                        p {
                            text-align: left;
                            font-weight: 500;
                            text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
                        }
                    }
                    .btn {
                        cursor: pointer;
                        padding: 0.5rem 0.65rem;
                        border-radius: 50%;
                        background-color: var(--BookingMain);
                        color: var(--BookingGold);

                        box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.2);
                        transition: background-color 300ms ease,
                                    transform 600ms cubic-bezier(0.59, 0, 0.06, 1);
                        &:hover {
                            background-color: var(--BookingDark);
                        }
                        ion-icon {
                            font-size: 1.25rem;
                        }
                        &.previous {
                            transform: translateX(calc(-100% - 1rem));
                            ion-icon {
                                margin-left: -1px;
                                margin-top: 1px;
                            }
                        }
                        &.next {
                            transform: translateX(calc(100% + 1rem));
                            ion-icon {
                                margin-right: -2px;
                                margin-top: 1px;
                            }
                        }
                    }
                }
            }
            &__description {
                margin-top: 0.75rem;
                padding-left: 3px;
                &__name {
                    text-align: left;
                    font-weight: 600;
                    font-size: 1.3rem;
                }
                &__options {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 0.75rem;
                    p.show-more {
                        color: var(--BookingGold);
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        cursor: pointer;
                        font-weight: 500;
                        transition: color 500ms ease;
                        &:hover {
                            color: var(--BookingGoldDark)
                        }
                        ion-icon {
                            transition: transform 300ms ease;
                            font-size: 1rem;
                            margin-left: 5px;
                        }
                        &.open {
                            ion-icon {
                                transform: rotate(180deg)
                            }
                        }
                    }
                    &__option {
                        display: flex;
                        align-items: center;
                        margin-right: 0.5rem;
                        &.persons {
                            p {
                                margin-top: 3px;
                            }
                            ion-icon, img {
                                margin-right: 8px;
                            }
                        }
                        ion-icon {
                            font-size: 1.4rem;
                            color: var(--BookingGold)
                        }
                        img {
                            width: 28px;
                        }
                        p {
                            font-weight: 500;
                            font-size: 0.9rem;
                        }
                    }
                }
                &__description {
                    margin-top: 0.5rem;
                    text-align: left;
                    p.description {
                        line-height: 1.35rem;
                        height: 0;
                        opacity: 0;
                        visibility: hidden;
                        transform: translateY(15px);
                        font-size: 0.9rem;
                        transition: opacity 600ms cubic-bezier(0.04, 0, 0.2, 1), transform 400ms cubic-bezier(0.04, 0, 0.2, 1);
                        &.open {
                            margin-top: 0.75rem;
                            visibility: visible;
                            height: auto;
                            opacity: 1;
                            transform: translateY(0);
                        }
                    }
                }
                &__footer {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    @include mobile {
                        justify-content: flex-start;
                        flex-wrap: wrap;
                        &__price {
                            margin-bottom: 0.75rem;
                        }
                        button {
                            width: 100%;
                        }
                    }
                    margin-top: 0.5rem;
                    &__price {
                        display: flex;
                        align-items: center;
                        p {
                            &.main-price {
                                font-size: 1.6rem;
                                font-weight: 700;
                                margin-right: 0.25rem;
                            }
                            &.small-text {
                                margin-top: 8px;
                                font-size: 0.75rem;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }
    }
    .rooms-not-found {
        margin-top: 2rem;
        padding: 0 1rem;
        p {
            line-height: 1.5rem;
        }
        button {
            margin: 1.2rem 0;
        }
        .phone {
            margin: 0.8rem 0 0.5rem;
        }
        .phone, .email {
            display: flex;
            align-items: center;
            justify-content: center;
            ion-icon {
                font-size: 1.3rem;
                margin-right: 8px;
            }
            p, a {
                font-weight: 500;
            }
            a {
                color: inherit;
                text-decoration: none;
            }
        }
    }
}