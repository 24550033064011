@import 'breakpoints';

@keyframes show {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}


.check-out {
	position: fixed;
	inset: 0;
	background-color: #fefefe;
	color: var(--BookingMain);
	z-index: 4998;
	overflow-y: auto;
	overflow-x: hidden;
	padding: 2.25rem 1rem 2rem;
	animation: show cubic-bezier(0.59, 0, 0.06, 1) 500ms forwards;
	h3.title {
		margin-bottom: 3rem;
		text-align: center;
	}
	&.loading {
		display: flex;
		align-items: center;
		justify-content: center;
		@include mobile {
			flex-direction: column;
		}
	}
	.loading-msg {
		margin-left: 1rem;
		@include mobile {
			margin-left: 0;
			margin-top: 1rem;
		} 
		font-size: 1.1rem;
		font-weight: 600;
	}
	.testing {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 100%;
		@include tablet {
			width: 80%;
		}
		@include desktop {
			width: 68%;
		}
		margin: 2.5rem auto 0;
		padding: 1rem 1.25rem;
		border: 1px solid var(--BookingMain);
		border-radius: 10px;
		p {
			margin-bottom: 1.5rem;
			font-weight: 600;
		}
		button {
			color: var(--BookingText)!important
		}
	}
	.paypal-wrapper {
		margin: 0 auto;
		width: 100%;
		display: flex;
		justify-content: center;
        @include tablet {
			width: 80%;
		}
		@include desktop {
			width: 40%;
		}
		@include hugeScreens {
			width: 30%;
		}
		div {
			width: 100%;
		}
	}
}