@import 'breakpoints';


.container.dates {
    padding-top: 1rem;
    padding-bottom: 0.9rem;
    margin-top: -3rem;
    .date-range {
        &-wrapper {
            width: 100%;
        }
        &-arrival, &-departure {
            .date-input {
                background-color: var(--BookingDark);
                color: var(--BookingText);
                border: var(--BookingBorder);
                border-radius: 8px;
                padding: 0.75rem 1rem;
                font-weight: 500;
                transition: border 500ms ease;
                &:focus {
                    border: 1px solid var(--BookingGoldDarker);
                    box-shadow: 0 0 1px var(--BookingGoldDarker);
                }
            }
            p {
                font-weight: 600;
                color: var(--BookingLight);
                text-align: left;
                margin-bottom: 4px;
                margin-left: 2px;
                font-size: 0.85rem;
            }
        }
        &-arrival {
            margin-bottom: 1rem;
        }
    }
    .next-btn {
        margin-top: 2.5rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            display: none;
            height: 20px;
            text-align: center;
            &.visible {
                display: block;
            }
        }
    }
}

.booking-calendar-popper {
    z-index: 1001!important;
}