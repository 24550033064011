@import 'breakpoints';

$pictureWidth: 440px;
$pictureHeight: 250px;


.container.summary {
    .error-loading-msg {
        margin-top: 2rem;
    }
    .items-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 1.5rem auto 0;
        width: 100%;
        @include tablet {
			width: 80%;
		}
		@include desktop {
			width: 68%;
		}
        .item {
            position: relative;
            background-color: var(--BookingMain);
		    box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
		    padding: 1rem 1.75rem;
            width: 100%;
            margin-bottom: 1.25rem;
            display: flex;
            @include notDesktop {
                flex-direction: column;
            }
            &__total {
                font-size: 1.35rem;
                font-weight: 700;
                margin-left: 5rem;
            }
            &__picture {
                position: relative;
                @include desktop {
                    min-width: $pictureWidth;
                    max-width: calc(#{$pictureWidth} + 1px);
                    margin-right: 1.25rem;
                    height: $pictureHeight !important;
                }
                @include notDesktop {
                    width: 100%;
                }
                @include tablet {
                    height: 300px;
                }
                @include mobile {
                    height: 250px;
                }
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                overflow: hidden;
                .item__img {
                    position: absolute;
                    inset: 0;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
            &__description {
                width: 100%;
                @include notDesktop {
                    margin-top: 0.75rem;
                }
                @include desktop {
                    min-height: $pictureHeight;
                }
                padding: 0.5rem 3px;
                button {
                    margin-top: 0.75rem;
                    width: 100%;
                }
                &__name {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    width: 100%;
                    font-weight: 600;
                    font-size: 1.3rem;
                    @include desktop {
                        font-size: 1.5rem;
                    }
                }
                &__options {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    margin-top: 0.75rem;
                    &__option {
                        display: flex;
                        align-items: center;
                        text-align: left;
                        margin-right: 0.5rem;
                        margin-bottom: 0.75rem;
                        &.persons {
                            p {
                                margin-top: 3px;
                            }
                            ion-icon, img {
                                margin-right: 8px;
                            }
                        }
                        ion-icon {
                            font-size: 1.35rem;
                            color: var(--BookingGold);
                            margin-right: 8px;
                        }
                        img {
                            width: 28px;
                        }
                        p {
                            font-weight: 600;
                            font-size: 0.85rem;
                        }
                    }
                }
                &__description {
                    margin-top: 0.5rem;
                    text-align: left;
                    p.included {
                        line-height: 1.35rem;
                        height: 0;
                        opacity: 0;
                        visibility: hidden;
                        transform: translateY(15px);
                        font-size: 0.9rem;
                        transition: opacity 600ms cubic-bezier(0.04, 0, 0.2, 1), transform 400ms cubic-bezier(0.04, 0, 0.2, 1);
                        &.open {
							margin-top: 1.25rem;
                            visibility: visible;
                            height: auto;
                            opacity: 1;
                            transform: translateY(0);
                        }
                    }
                    p.show-more {
                        color: var(--BookingGold);
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        cursor: pointer;
                        font-weight: 500;
                        transition: color 500ms ease;
                        margin-top: 0.5rem;
                        &:hover {
                            color: var(--BookingGoldDark)
                        }
                        ion-icon {
                            transition: transform 300ms ease;
                            font-size: 1rem;
                            margin-left: 5px;
                        }
                        &.open {
                            ion-icon {
                                transform: rotate(180deg)
                            }
                        }
                    }
                }
            }
        }
        .total-card {
            background-color: var(--BookingMain);
            box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            padding: 1.25rem 1.25rem 1rem;
            margin-top: 1.25rem;
            width: 100%;
            .item-price, .total-price {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                .item-name, .item-total {
                    font-weight: 600;
                    font-size: 0.95rem;
                    color: var(--BookingLight)
                }
                .total-name, .total-total {
                    font-weight: 700;
                    font-size: 1.35rem;
                }
            }
            .item-price {
                margin-bottom: 0.5rem;
                margin-right: 0.5rem;
            }
            .total-price {
                margin-top: 1rem;
                padding-top: 0.75rem;
                border-top: 1px solid var(--BookingGray);
            }
        }
        .checkout-btn {
            margin-top: 1.25rem;
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }
    }
}