@import 'breakpoints';


header.fixed {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top:0; left: 0; right: 0;
    height: 3.7rem;
    background-color: var(--lowOpacityDark);
    color: var(--mainLight);
    box-shadow: 5px 10px 45px var(--lightShadow);
    transition: transform 1s cubic-bezier(0.59, 0, 0.06, 1);
    transform: translateY(0);
    z-index: 3;
    &.hidden {
        transform : translateY(-100%);
    }
    .ham-menu {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 2vw;
        @include mobile {
            padding-left: 5vw;
        }
        .actual-ham-menu {
            position: relative;
            cursor: pointer;
            height: 23px;
            width: 32px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            span {
                display: block;
                width: 100%;
                height: 2px;
                background-color: var(--mainGold);
            }
        }
    }
    .icon {
        position: absolute;
        top: 0.45rem;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
        img {
            height: 3rem;
        }
    }
    .book-btn {
        height: 100%;
        button {
            color: var(--light);
            height: 100%;
            @include notDesktop {
                font-size: 0.9rem;
                padding: {
                    left: 5vw;
                    right: 5vw;
                };
            }
        }
    }
}