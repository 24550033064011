@import 'breakpoints';

.container.details {
	.wrapper {
		display: flex;
        align-items: center;
        flex-direction: column;
        margin: 1.5rem auto 0;
        width: 100%;
        @include tablet {
			width: 80%;
		}
		@include desktop {
			width: 68%;
		}
		.content-wrapper {
			position: relative;
            background-color: var(--BookingMain);
		    box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
		    padding: 1rem 1.75rem;
            width: 100%;
            margin-bottom: 1.25rem;
			&.payment {
				margin-top: 0.75rem;
				.total-card {
					margin-top: 0.75rem;
					width: 100%;
					.item-price, .total-price {
						display: flex;
						align-items: center;
						justify-content: space-between;
						width: 100%;
						.item-name, .item-total {
							font-weight: 600;
							font-size: 0.95rem;
							color: var(--BookingLight)
						}
						.total-name, .total-total {
							font-weight: 700;
							font-size: 1.35rem;
						}
					}
					.item-price {
						margin-bottom: 0.5rem;
						margin-right: 0.5rem;
					}
					.total-price {
						margin-top: 1rem;
						padding-top: 0.75rem;
						border-top: 1px solid var(--BookingGray);
					}
				}
				.pay-note {
					width: 100%;
					text-align: right;
					font-size: 0.8rem;
					font-weight: 600;
					line-height: 1.3rem;
					padding-bottom: 1rem;
					margin-top: 0.75rem;
					color: var(--BookingLight);
					border-bottom: 1px solid var(--BookingGray);
					@include mobile {
						font-size: 0.7rem;
						line-height: 1rem;
						text-align: center;
					}
				}
				.checkboxes {
					width: 100%;
					margin-top: 1.25rem;
					.checkbox {
						display: flex;
						align-items: center;
						justify-content: flex-start;
						margin-bottom: 1rem;
						padding-left: 1rem;
						@include mobile {
							padding: 0;
						}
						p {
							text-align: left;
							margin-left: 0.5rem;
							font-size: 1rem;
							line-height: 1.45rem;
							@include mobile {
								font-size: 0.9rem;
								line-height: 1.3rem;
							}
							font-weight: 500;
						}
					}
				}
				.button-wrapper {
					display: flex;
					justify-content: flex-end;
					width: 100%;
					margin-bottom: 0.25rem;
				}
			}
			&.form {
				.input-wrapper {
					width: 100%;
					textarea {
						min-width: 100%;
						max-width: 100%;
						min-height: 50px;
						max-height: 100px;
					}
				}
				.grid-wrapper {
					width: 100%;
					display: grid;
					grid-template-columns: 1fr 1fr;
					@include mobile {
						grid-template-columns: 1fr;
					}
					grid-template-rows: auto;
					grid-gap: 1rem;
					margin-bottom: 1rem;
				}
			}
		}
	}
}