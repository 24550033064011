@import 'breakpoints';

@keyframes wheelMove {
    0% {
        opacity: 0;
        transform: translateY(-1rem);
    }
    
    100% {
        opacity: 1;
        transform: translateY(0.5rem);
    }
}

@keyframes arrowMovement {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 0.25;
    }
    50% {
        opacity: 0.5;
    }
    75% {
        opacity: 0.75;
    }
    100% {
        opacity: 1;
    }
}


.scroll-button {
    position: absolute; 
    bottom: 1.5rem;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    .mouse {
        width: 1.3rem;
        height: 2.5rem;
        border: 2px solid var(--light);
        border-radius: 2rem;
        margin-bottom: 0.2rem;
        display: flex;
        box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.15);
        span {
            display: block;
            width: 0.5rem;
            height: 0.5rem;
            background-color: var(--light);
            border-radius: 50%;
            margin: auto;
            animation: wheelMove 1500ms linear infinite;
        }
    }
    .arrow {
        span {
            display: block;
            width: 0.5rem;
            height: 0.5rem;
            border-right: 2px solid var(--light);
            border-bottom: 2px solid var(--light);
            transform: rotate(45deg);
            animation: arrowMovement 800ms alternate infinite;
        }
    }
}