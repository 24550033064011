@import 'breakpoints';

.bg-effect {
    z-index: 4;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;
    display: none;
    &.visible {
        display: block;
    }

}