@import 'breakpoints';

header.main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute; top:0;
    left: 0; right: 0;
    padding: 2rem 5vw;
    transition: padding 300ms ease;
    z-index: 2;
    @include mobile {
        padding: 0;
    }
    .ham-menu {
        @include mobile {
            margin-left: 5vw;
            margin-top: 0.5rem;
        }
        .actual-ham-menu {
            height: 26px;
            width: 35px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            span {
                display: block;
                height: 2px;
                width: 100%;
                background-color: var(--light);

            }
        }
    }
    .logo {
        position: absolute;
        top: 4rem;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
        img {
            transition: width 300ms ease, height 300ms ease, transform 1s ease-in-out;
            &:hover {
                transform: scale(1.04);
            }
            width: 20rem;
            @include mobile {
                width: 13rem;
            }
        }
    }
    .book-btn button {
        @include tablet {
            padding: {
                top: 0.7rem;
                bottom: 0.7rem;
            }
        }
    }
}