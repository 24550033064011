@import 'breakpoints';


$imageHeight: 250px;
$imageWidth: 425px;



.container.rates {
	.error-msg {
		padding: 0 1rem;
		line-height: 1.5rem;
	}
	.unavailable-error {
		margin-top: 1rem;
		width: 100%;
		.unavailable-msg {
			font-size: 1.35rem;
			font-weight: 500;
			line-height: 1.75rem;
		}
	}
	.wrapper {
		display: flex;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;
        width: 100%;
        @include tablet {
			width: 80%;
		}
		@include desktop {
			width: 68%;
		}
		.unavailable {
			&-msg {
				margin-top: 1.25rem;
				padding: 0 1rem;
				text-align: center;
			}
			&-btn {
				margin-top: 1rem;
			}
		}
		.room-card {
			width: 100%;
			background-color: var(--BookingMain);
            border-radius: 14px;
            padding: 1rem 1.2rem;
            box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.2);
			display: flex;
			align-items: flex-start;
			@include notDesktop {
				flex-direction: column;
				align-items: center;
			}
            &__picture {
                position: relative;
                height: $imageHeight;
				@include desktop {
					min-width: $imageWidth;
					margin-right: 1.25rem;
				}
				@include notDesktop {
					width: 100%;
					margin-bottom: 0.75rem;
				}
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                overflow: hidden;
                .room-card__image {
                    position: absolute;
                    top: 0; left: 0; right: 0; bottom: 0;
                    z-index: 1;
                    transform: translateX(101%);
                    transition: transform 300ms cubic-bezier(0.59, 0, 0.06, 1);
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    &.active {
                        transform: translateX(0);
                    }
                    &.passed {
                        transform: translateX(-101%);
                    }
                }
                .overlay {
                    position: absolute;
                    top:0;left:0;right:0;bottom:0;
                    z-index: 2;
                    overflow: hidden;
                    padding: 0 0.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    &:hover {
                        .btn.next, .btn.previous {
                            transform: translateX(0)
                        }
                        .counter {
                            opacity: 1;
                        }
                    }
                    .counter {
                        position: absolute;
                        bottom: 0.5rem;
                        right: 0.8rem;
                        opacity: 0; visibility: visible;
                        transition: opacity 300ms ease;
						background-color: var(--BookingMain);
						padding: 5px 8px;
						border-radius: 6px;
                        p {
                            text-align: left;
                            font-weight: 500;
                            text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
                        }
                    }
                    .btn {
                        cursor: pointer;
                        padding: 0.5rem 0.65rem;
                        border-radius: 50%;
                        background-color: var(--BookingMain);
                        color: var(--BookingGold);

                        box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.2);
                        transition: background-color 300ms ease,
                                    transform 600ms cubic-bezier(0.59, 0, 0.06, 1);
                        &:hover {
                            background-color: var(--BookingDark);
                        }
                        ion-icon {
                            font-size: 1.25rem;
                        }
                        &.previous {
                            transform: translateX(calc(-100% - 1rem));
                            ion-icon {
                                margin-left: -1px;
                                margin-top: 1px;
                            }
                        }
                        &.next {
                            transform: translateX(calc(100% + 1rem));
                            ion-icon {
                                margin-right: -2px;
                                margin-top: 1px;
                            }
                        }
                    }
                }
            }
			&__description {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				width: 100%;
				@include desktop {
					min-height: $imageHeight;
					padding: 0.25rem 0;
				}
				@include notDesktop {
					padding: 0.25rem 0.5rem 0.5rem;
				}
			}
			&__actual-description {
				text-align: left;
				font-size: 0.9rem;
				line-height: 1.35rem;
			}
			&__name {
				text-align: left;
				font-weight: 600;
				font-size: 1.45rem;
				margin-bottom: 1rem;
			}
			&__maxP {
				display: flex;
				align-items: center;
				margin-bottom: 0.75rem;
				ion-icon {
					font-size: 1.4rem;
					color: var(--BookingGold);
					margin-right: 8px;
				}
				p {
					font-weight: 500;
					font-size: 0.9rem;
					margin-top: 3px;
				}
			}
			.show-more {
				color: var(--BookingGold);
				display: flex;
				align-items: center;
				justify-content: flex-end;
				width: 100%;
				cursor: pointer;
				font-weight: 500;
				transition: color 500ms ease;
				margin-top: 1rem;
				&:hover {
					color: var(--BookingGoldDark)
				}
				ion-icon {
					transition: transform 300ms ease;
					font-size: 1rem;
					margin-left: 5px;
				}
				&.open {
					ion-icon {
						transform: rotate(180deg)
					}
				}
			}
		}
		.grid-wrapper {
			position: relative;
			display: grid;
			grid-template-columns: 1fr 1fr;
			@include notDesktop {
				grid-template-columns: 1fr;
			}
			grid-template-rows: auto;
			grid-gap: 1.25rem;
			width: 100%;
			margin-top: 1.25rem;
			.right-column {
				display: flex;
				align-items: center;
				flex-direction: column;
				width: 100%;
				.section:not(:last-child) {
					margin-bottom: 1.25rem;
				}
			}
			.section {
				width: 100%;
				&__title {
					font-size: 1.5rem;
					font-weight: 600;
					margin-bottom: 0.75rem;
				}
				&__wrapper {
					display: flex;
					flex-direction: column;
					align-items: center;
					position: relative;
					background-color: var(--BookingMain);
					box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.2);
					border-radius: 10px;
					padding: 1rem 1.5rem;
					width: 100%;
				}
				&.occupancy {
					.section__wrapper {
						.input-wrapper {
							width: 100%;
							&:not(:last-child) {
								margin-bottom: 1rem;
							}
							select {
								cursor: pointer;
							}
						}
						.extra-bed {
							width: 100%;
							font-size: 0.8rem;
							font-weight: 600;
							text-align: right;
							margin-top: 0.75rem;
						}
					}
				}
				&.products {
					.section__wrapper {
						.product {
							width: 100%;
							padding: 1.25rem 0.5rem 1rem;
							display: flex;
							&:first-child {
								padding-top: 0;
							}
							&:last-child {
								padding-bottom: 0;
							}
							&:not(:last-child) {
								border-bottom: 1px solid var(--BookingGray)
							}
							&__picture {
								width: 160px;
								min-width: 159px;
								max-width: 161px;
								height: 145px;
								@include mobile {
									width: 100px;
									min-width: 99px;
									max-width: 101px;
									height: 85px;
								}
								margin-right: 1.25rem;
								overflow: hidden;
								border-radius: 8px;
								img {
									width: 100%;
									height: 100%;
									object-fit: cover;
								}
							}
							&__description {
								width: 100%;
								min-height: 150px;
								display: flex;
								flex-direction: column;
								h3 {
									margin-top: 0.5rem;
									font-size: 1.25rem;
									text-align: left;
									font-weight: 600;
								}
								.included {
									width: 100%;
									line-height: 1.35rem;
									text-align: left;
									height: 0;
									opacity: 0;
									visibility: hidden;
									transform: translateY(15px);
									font-size: 0.9rem;
									transition: opacity 600ms cubic-bezier(0.04, 0, 0.2, 1), transform 400ms cubic-bezier(0.04, 0, 0.2, 1);
									&.open {
										margin-top: 0.75rem;
										visibility: visible;
										height: auto;
										opacity: 1;
										transform: translateY(0);
									}
								}
								.show-more {
									display: flex;
									align-items: center;
									justify-content: flex-end;
									width: 100%;
									cursor: pointer;
									color: var(--BookingText);
									font-weight: 500;
									transition: color 500ms ease;
									margin-top: 0.75rem;
									font-size: 0.9rem;
									&:hover {
										color: var(--BookingLight)
									}
									ion-icon {
										transition: transform 300ms ease;
										font-size: 1rem;
										margin-left: 5px;
									}
									&.open {
										ion-icon {
											transform: rotate(180deg)
										}
									}
								}
							}
							&__footer {
								width: 100%;
								margin-top: 1rem;
								display: flex;
								align-items: center;
								justify-content: space-between;
								flex-wrap: wrap;
								.price {
									display: flex;
									align-items: center;
									margin-bottom: 0.75rem;
									.main-price {
										font-size: 1.6rem;
										font-weight: 700;
										margin-right: 0.25rem;
									}
									.small-text {
										margin-top: 9px;
										font-size: 0.75rem;
										font-weight: 500;
									}
								}
							}
						}
					}
				}
				&.rates {
					.section__wrapper {
						padding-top: 1.25rem;
						padding-bottom: 1.25rem;
						.rates-section {
							&__header {
								width: 100%;
								display: flex;
								align-items: center;
								justify-content: space-between;
								h3 {
									font-size: 1.35rem;
								}
								.show-more {
									display: flex;
									align-items: center;
									justify-content: flex-end;
									cursor: pointer;
									color: var(--BookingGold);
									font-weight: 500;
									transition: color 500ms ease;
									&:hover {
										color: var(--BookingGoldDark)
									}
									ion-icon {
										transition: transform 300ms ease;
										font-size: 1rem;
										margin-left: 5px;
									}
									&.open {
										ion-icon {
											transform: rotate(180deg)
										}
									}
								}
							}
							&__footer {
								width: 100%;
								display: flex;
								align-items: center;
								justify-content: space-between;
								flex-wrap: wrap;
								margin-top: 2rem;
								.price {
									display: flex;
									align-items: center;
									margin-bottom: 0.25rem;
									.main-price {
										font-size: 1.6rem;
										font-weight: 700;
										margin-right: 0.25rem;
									}
									.small-text {
										margin-top: 9px;
										font-size: 0.75rem;
										font-weight: 500;
									}
								}
							}
						}
						.included {
							width: 100%;
							line-height: 1.35rem;
							text-align: left;
							height: 0;
							opacity: 0;
							visibility: hidden;
							transform: translateY(15px);
							font-size: 0.9rem;
							transition: opacity 600ms cubic-bezier(0.04, 0, 0.2, 1), transform 400ms cubic-bezier(0.04, 0, 0.2, 1);
							&.open {
								margin-top: 0.75rem;
								visibility: visible;
								height: auto;
								opacity: 1;
								transform: translateY(0);
							}
						}
					}
				}
			}
		}
	}
}