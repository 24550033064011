@import 'breakpoints';

.modal {
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 5;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    .content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: var(--mainDark);
        color: var(--mainLight);
        padding: 2rem 2.5rem 1.3rem;
        border-radius: 10px;
        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.15);
        text-align: center;
        button {
            font-size: 0.9rem;
            padding-top: 0.7rem;
            padding-bottom: 0.7rem;
            margin-top: 1rem;
        }
        p {
            font-weight: 500;
        }
        @include mobile {
            width: 95%;
            padding: 1.5rem 1rem;
        }
    }
    &.show {
        display: flex;
    }
}

.booking-form {
    position: absolute;
    left: 0; right: 0;
    bottom: 6rem;
    .form-container {
        --rowHeight: 3.5rem;
        box-shadow: 10px 10px 45px rgba(0, 0, 0, 0.15);
        margin: auto;
        display: grid;
        grid-template-rows: repeat(3, var(--rowHeight));
        grid-template-columns: 1fr;
        height: calc(var(--rowHeight) * 3);
        width: 80%;
        @include tablet {
            height: calc(var(--rowHeight) * 2);
            grid-template-columns: 1fr 1fr;
            width: 600px;
        }
        @include desktop {
            height: var(--rowHeight);
            grid-template-columns: 1.5fr 1.5fr 1fr;
            width: 1024px;
        }
        .date-input {
            background-image: url(../../images/calendar.png);
            background-size: 18px;
            background-repeat: no-repeat;
            background-position: 95% center;
            background-color: var(--light);
            border-radius: 0;
            transition: background-color 500ms ease-in-out;
            &:hover {
                background-color: var(--darker);
            }
            color: var(--dark);
            //padding: 1rem 1.2rem;
            height: var(--rowHeight);
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            &:first-child {
                border-right: 1px solid var(--lowOpacityDark);
                @include mobile {
                    border-right: none!important;
                    border-bottom: 1px solid var(--lowOpacityDark);
                }
            }
            .placeholder-text {
                font-weight: 600;
                opacity: 0.8;
            }
        }
        button:not(.calendar-popper button) {
            color: var(--light);
            height: var(--rowHeight);
            @include tabletNotDesktop {
                grid-column-start: 1;
                grid-column-end: 3;
            }
            background-image: none;
            background-color: var(--mainGold);
            border: none;
            &:hover {
                background-color: var(--hoverGold)
            }
            @include mobile {
                width: 100%;
            }
        }
    }
}

.calendar-popper {
    button {
        outline: none;
    }
}

.react-datepicker-wrapper{
    width: 100%;
    .react-datepicker__input-container {
        width: 100%;
        input {
            width: 100%;
        }
    }
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: var(--mainGold)!important;
    &:hover {
        background-color: var(--hoverGold)!important
    }
}
.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
    background-color: var(--mainGold)!important;
}

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
    background-color: var(--hoverGold)!important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: var(--mainGold)!important;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
    background-color: var(--hoverGold)!important;
}


.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background-color: var(--mainGold)!important;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
    background-color: var(--hoverGold)!important;
}

.react-datepicker__day--in-selecting-range ,
.react-datepicker__month-text--in-selecting-range ,
.react-datepicker__quarter-text--in-selecting-range ,
.react-datepicker__year-text--in-selecting-range {
    background-color: var(--goldLowOp)!important;
}