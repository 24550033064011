@import 'breakpoints';

section.mobile-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    @include tablet {
        width: 60vw;
    }
    @include desktop {
        width: 40vw;
    }
    position: fixed;
    z-index: 5;
    left: 0; top: 0; bottom: 0;
    background-color: var(--light);
    color: var(--dark);
    transform: translateX(-100%);
    transition: transform 1s cubic-bezier(0.59, 0, 0.06, 1);
    &.open {
        box-shadow: 10px 20px 45px var(--lightShadow);
        transform: translateX(0);
    }
    .close {
        position: absolute;
        left: 5vw;
        top: 2rem;
        z-index: 4;
        @include tablet {
            left: 5vw;
            top: 3rem;
        }
        @include desktop {
            left: 3vw;
            top: 3rem;
        }
        height: 30px; width: 30px;
        cursor: pointer;
        span {
            display: block;
            background-color: var(--mainBlue);
            width: 100%;
            height: 3px;
            transform-origin: 13px 2px;
            &:nth-child(1) {transform: rotate(45deg)}
            &:nth-child(2) {transform: rotate(-45deg)}
        }
    }
    .menu__content {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 2rem 5vw;
        @include mobile {
            padding: 2rem 10vw;
        }
        .title {
            font-size: 2.2rem;
            font-weight: 200;
            letter-spacing: 0.5px;
            margin-bottom: 3rem;
            color: var(--darkBlue);
        }
        .links {
            list-style: none;
            li {
                &:not(:last-child) {
                    margin-bottom: 1.3rem;
                }
                a {
                    font-family: 'Josefin Sans';
                    font-size: 1.4rem;
                    font-weight: 600;
                    text-decoration: none;
                    color: var(--mainBlue);
                    transition: color 300ms ease;
                    &:hover {
                        color: var(--mainGold)
                    }
                }
            }
        }
    }
    .line {
        display: block;
        height: 1px;
        background-color: var(--hoverBlue);
        width: 40%;
        margin: 2rem 0 1.5rem;
        opacity: 0.5;
    }
    .social-media {
        list-style: none;
        display: flex;
        align-items: center;
        li {
            &:not(:last-child) {
                margin-right: 0.7rem;
            }
            a {
                font-size: 1.6rem;
                text-decoration: none;
                color: var(--mainBlue);
                transition: color 400ms ease;
                &:hover {
                    color: var(--mainGold)
                }
            }
        }
    }
    .copyright, .links {
        font-size: 0.8rem;
        font-weight: 500;
        margin-top: 1rem;
        a {
            margin-top: 0.8rem;
            text-decoration: none;
            color: var(--mainBlue);
            transition: color 400ms ease;
            &:hover {
                color: var(--mainGold);
            }
        }
    }
    .links {
        margin-top: 0.5rem;
        span {
            margin: -3px 0.3rem 0;
        }
    }
}