@import 'breakpoints';

@keyframes breath {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1)
    }
    100% {
        transform: scale(1)
    }
}

.banner {
    min-height: 100vh;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .header-effect, .pic {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
    }
    .header-effect {
        z-index: -1;
        background-image: linear-gradient(rgba(0, 0, 0, 0.7), 10%, rgba(0, 0, 0, 0));
    }
    .banner-video {
        position: absolute;
        z-index: -2;
        top: 0; left: 0; right: 0; bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        .loading {
            position: absolute;
            top: 0; left: 0; right: 0; bottom: 0;
            background-color: var(--darkLowOp);
            background-image: url(./../../images/Loading.gif);
            background-size: 250px;
            background-position: center;
            background-repeat: no-repeat;
            opacity: 1;
            transition: opacity 500ms ease;
            &.hidden {
                opacity: 0;
            }
        }
        video {
            min-width: 100%;
            min-height: 100%;
            flex-shrink: 0;
            opacity: 0;
            &.start {
                opacity: 1;
            }
        }
    }
    .banner-video {
        @include mobile {
            display: none;
        }
    }
    .pic {
        z-index: -3;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        opacity: 0;
        transition: opacity 1s ease;
        animation: breath 20s ease-out infinite forwards;
        &-container {
            z-index: -3;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0; left: 0; right: 0; bottom: 0;
            overflow: hidden;
        }
        &.current {
            opacity: 1;
        }
        &.video-initialized {
            @include tablet {
                display: none;
                animation: none;
            }
        }
    }
    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
    }
}