@import 'breakpoints';

@keyframes showFinish {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes showContent {
	from {
		opacity: 0;
		transform: translateY(-40px)
	}
	to {
		opacity: 1;
		transform: translateY(0)
	}
}

.Finish {
	position: fixed;
	inset: 0;
	background-color: var(--BookingMain);
	color: var(--BookingText);
	z-index: 4999;
	overflow-y: auto;
	overflow-x: hidden;
	padding: 2.25rem 1rem 2rem;
	opacity: 0;
	animation: showFinish cubic-bezier(0.59, 0, 0.06, 1) 800ms forwards;
	display: flex;
	align-items: center;
	justify-content: center;
	.content {
		display: flex;
		align-items: center;
		flex-direction: column;
		opacity: 0;
		transform: translateY(-20px);
		animation: showContent cubic-bezier(0.59, 0, 0.06, 1) 1500ms forwards;
		animation-delay: 1500ms;
		margin: 0 auto;
		width: 100%;
		@include tablet {
			width: 80%;
		}
		@include desktop {
			width: 68%;
		}
		.name-flex {
			display: flex;
			align-items: center;
			ion-icon {
				color: var(--BookingGold);
				font-size: 3.5rem;
				margin-right: 1rem;
				@include mobile {
					font-size: 2.5rem;
					margin-right: 1rem;
				}
			}
			p {
				font-weight: 200;
				font-size: 3rem;
				@include mobile {
					font-size: 2rem;
				}
			}
		}
		.description {
			margin-top: 2.5rem;
			margin-bottom: 1.75rem;
			padding: 0 1.5rem;
			@include mobile {
				padding: 0;
			}
			text-align: center;
			p {
				font-size: 1.1rem;
				line-height: 1.75rem;
				font-weight: 300;
				@include mobile {
					font-size: 0.9rem;
					line-height: 1.5rem;
				}
				span {
					font-weight: 500;
					line-height: 4rem;
				}
			}
		}
		.buttons-wrapper {
			display: flex;
			align-items: center;
			@include mobile {
				flex-direction: column;
			}
			button {
				&:not(:last-child) {
					margin-right: 1.25rem;
					@include mobile {
						margin-right: 0;
						margin-bottom: 0.75rem;
					}
				}
			}
		}
	}
}