@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');
@import 'breakpoints';

* {
    padding: 0; margin: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', 'Josefin Sans';
    scroll-behavior: smooth;
}


:root { // colors ESKETITI
    --mainGold: #c9905a;
    --goldLowOp: rgba(201, 144, 90, 0.3);
    --darkGold: #b37842;
    --hoverGold: #e5b98f;
    --mainBlue: #1b3c80;
    --blueLowOp: rgba(27, 60, 128, 0.2);
    --darkBlue: #0c2558;
    --hoverBlue: #4f6ead;
    --light: #efefef;
    --lightLowOp: rgba(221, 221, 221, 0.4);
    --lightVeryLowOp: rgba(221, 221, 221, 0.85);
    --darker: #ddd;
    --dark: #232323;
    --darkLowOp: rgba(35, 35, 35, 0.3);
    --darkVeryLowOp: rgba(35, 35, 35, 0.9);
    --footerBG: #ececec;
}

body {
    width: 100%;
    color: var(--mainLight);
    background-color: var(--mainDark);
    overflow-x: hidden;
    &.dark {
        --mainDark: #232323;
        --lowOpacityDark: rgba(35, 35, 35, 0.95);
        --veryLowOpacityDark: rgba(35, 35, 35, 0.6);
        --mainLight: #efefef;
        --hoverLight: rgb(221, 221, 221);
        --lowOpacityLight: rgba(239, 239, 239, 0.85);
        --shadow: rgba(0, 0, 0, 0.12);
        --lightShadow: rgba(255, 255, 255, 0.15);
    }
    &.light {
        --mainDark: #efefef;
        --lowOpacityDark: rgba(220, 220, 220, 0.95);
        --veryLowOpacityDark: rgba(220, 220, 220, 0.6);
        --mainLight: #232323;
        --hoverLight: rgb(34, 34, 34);
        --lowOpacityLight: rgba(16, 16, 16, 0.85);
        --shadow: rgba(255, 255, 255, 0.12);
        --lightShadow: rgba(0, 0, 0, 0.15);
        
    }
}
::selection {
    color: var(--hoverGold);
    background-color: var(--mainLight);
}


button:not(.react-datepicker button), input[type='submit'] {
    padding: 1rem 1.5rem;
    font-weight: 600;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 2px;
    transition: color 300ms ease,
                background-color 300ms ease,
                background-position 300ms ease,
                border 300ms ease,
                box-shadow 500ms ease,
                padding 300ms ease;
    font-family: 'Josefin Sans';
    text-transform: uppercase;
    background-size: 150%;
    background-position: 0 center;
    &.gold.primary {
        border: 2px solid var(--mainGold);
        background-image: linear-gradient(45deg, var(--darkGold), var(--hoverGold));
        color: var(--light);
        box-shadow: 0px 0px 0px var(--mainGold);
        &:hover {
            box-shadow: 0px 0px 4px var(--mainGold);
            background-position: 100% center;
        }
    }
    &.gold.secondary {
        border: 2px solid var(--mainGold);
        color: var(--mainGold);
        background-color: transparent;
        &:hover {
            background-color: var(--mainGold);
            color: var(--light);
        }
    }
    &.blue.primary {
        border: 2px solid var(--mainBlue);
        background-image: linear-gradient(45deg, var(--darkBlue), var(--hoverBlue));
        color: var(--light);
        &:hover {
            background-position: 100% center;
        }
    }
    &.blue.secondary {
        border: 2px solid var(--mainBlue);
        color: var(--mainBlue);
        background-color: transparent;
        &:hover {
            background-color: var(--mainBlue);
            color: var(--light);
        }
    }
}

input {
    border: none;
    outline: none;
    font-size: 1rem;
    padding: 1rem 1.5rem;
    border-radius: 2px;
}
.explore-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    font-size: 1.1rem;
    p {
        font-family: 'Josefin Sans';
        font-weight: 600;
        color: var(--mainLight);
        transition: color 500ms ease;
        padding-bottom: 0.4rem;
        text-transform: uppercase;
    }
    span {
        display: block;
        height: 2px;
        background-color: var(--mainGold);
        width: 100%;
        transition: width 500ms ease;
    }
    &:hover {
        span {
            width: 60%;
        }
        p {
            color: var(--hoverGold);
        }
    }
}
.hide-for-mobile {
    @include notDesktop{
        display: none!important;
    }
}
.hide-for-desktop {
    @include desktop {
        display: none!important;
    }
}
.fill {
    height: 300vh;
}

.map-element {
    padding-top: 15vh;
    width: 95%;
    height: 650px;
    margin: auto;
    @include tablet {
        width: 90%;
    }
}