@import 'breakpoints';

header.Booking-Header {
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 10;
    * {
        font-weight: 400;
    }
    .Top-Header {
        --BookingTopHeaderHeight: 65px;
        width: 100%;
        height: var(--BookingTopHeaderHeight);
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--BookingMain);
        border-bottom: var(--BookingBorder);
        .logo {
            cursor: pointer;
            padding-left: 1rem;
            p {
                font-weight: 500;
            }
            img {
                height: 40px;
                margin-right: 1rem;
            }
            display: flex;
            align-items: center;
        }
        .right-side {
            height: 100%;
            display: flex;
            align-items: center;
            .options {
                display: flex;
                align-items: center;
                .option {
                    position: relative;
                    cursor: pointer;
                    margin-right: 2rem;
                    @include mobile {
                        margin-right: 1rem;
                    }
                    .dropdown {
                        position: absolute;
                        z-index: 50;
                        top: calc(100% + 1.12rem);
                        height: 0;
                        overflow-y: auto;
                        transition: height 500ms cubic-bezier(0.59, 0, 0.06, 1);
                        background-color: var(--BookingMain);
                        left: -1.5rem;
                        width: 9rem;
                        border-radius: 10px;
                        box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.2);
                        &.lang {
                            left: -2rem;
                            @include notDesktop {
                                left: -4rem;
                            }
                            width: 10rem;
                        }
                        text-align: left;
                        &-option {
                            p {
                                font-weight: 500;
                            }
                            padding: 0.65rem 1rem;
                            transition: background-color 600ms ease;
                            &:hover {
                                background-color: var(--BookingBorderColor)
                            }
                            &.current p {
                                color: var(--BookingGold);
                            }
                        }
                        &.open {
                            height: 15rem;
                        }
                    }
                    .desktop {
                        display: flex;
                        align-items: center;
                        p {
                            font-size: 0.9rem;
                            margin-right: 2px;
                            font-weight: 500;
                        }
                        ion-icon {
                            font-size: 1.3rem;
                            transition: transform 500ms ease,
                            margin 500ms ease;
                        }
                    }
                    .mobile ion-icon {
                        font-size: 1.3rem;
                    }
                    &.open .desktop ion-icon {
                        transform: rotate(180deg);
                    }
                }
            }
            .escape {
                height: 100%;
                width: var(--BookingTopHeaderHeight);
                display: flex;
                align-items: center;
                justify-content: center;
                border-left: var(--BookingBorder);
                transition: background-color 800ms ease-in-out;
                &:hover {
                    background-color: var(--BookingBorderColor);
                }
                cursor: pointer;
                .escape-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    font-size: 2rem;
                    cursor: pointer;
                }
            }
        }
    }
    .Progress-Header {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem 2rem;
        @include notDesktop {
            padding: 0.3rem 2rem;
        }
        border-bottom: var(--BookingBorder);
        background-color: var(--BookingMain);
        .line {
            position: absolute;
            z-index: 2;
            width: calc(100% - 4rem);
            display: block;
            background-color: var(--BookingBorderColor);
            height: 1.5px
        }
        .steps {
            position: relative;
            z-index: 5;
            width: 100%;
            list-style: none;
            display: flex;
            li {
                position: relative;
                display: flex;
                align-items: center;
                z-index: 5;
                padding: 0.5rem 0.8rem;
                p {
                    font-weight: 500;
                }
                ion-icon {
                    margin-right: 0.5rem;
                    font-size: 1.3rem;
                }
            }
            @include desktop {
                align-items: center;
                justify-content: space-between;
                span {
                    flex-grow: 1;
                    height: 1.5px;
                    width: 1px;
                    background-color: var(--BookingGray);
                    &.active {
                        background-color: var(--BookingGold)
                    }
                    &.current {
                        background-color: var(--BookingGold)
                    }
                }
                li {
                    border-radius: 8px;
                    transition: background-color 200ms ease;
                    color: var(--BookingGray);
                    background-color: var(--BookingMain);
                    &.available {
                        cursor: pointer;
                        color: var(--BookingText)!important;
                    }
                    &.active {
                        cursor: pointer;
                        color: var(--BookingGold);
                        &:hover {
                            background-color: var(--BookingHover);
                        }
                    }
                    &.current {
                        background-color: var(--BookingHover);
                        color: var(--BookingGold);
                    }
                }
            }
            @include notDesktop {
                flex-direction: column;
                align-items: flex-start;
                cursor: pointer;
                li {
                    padding: 0.7rem 0.8rem;
                    color: var(--BookingBorderColor);
                    &.active {
                        color: var(--BookingGold);
                    }
                    &.available {
                        color: var(--BookingText)
                    }
                    &:not(&.current) {
                        display: none;
                    }
                }
                .arrow {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-40%);
                    display: flex;
                    align-items: center;
                    p {
                        margin-right: 0.4rem;
                        font-weight: 500;
                        font-size: 0.9rem;
                    }
                    ion-icon {
                        font-size: 1.4rem; 
                    }   
                }
                &.shown {
                    .arrow {
                        top: 20px;
                        ion-icon {
                            transform: rotate(-180deg)
                        }
                    }
                    li:not(li.current) {
                        display: flex!important;
                    }
                }
            }
        }
    }
    .mobile-menu-effect {
        position: fixed;
        top: 0; left: 0; right: 0; bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: -1;
        display: none;
        &.shown {
            @include notDesktop{
                display: block;
            }
        }
    }
}

