@import 'breakpoints';

.Loading {
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;
    background-color: var(--BookingMain);
    color: var(--BookingText);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    z-index: 50;
    opacity: 1;
    transition: opacity 600ms cubic-bezier(0.59, 0, 0.06, 1);
    &.hide {
        opacity: 0;
    }
}