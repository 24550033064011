.Booking-App {
    --BookingMain: #2b2b2e;
    --BookingBorder: 1px solid #4b4b4d;
    --BookingBorderColor: #4b4b4d;
    --BookingText: #ffffff;
    --BookingHover: #202020;
    --BookingDark: #1b1b1b;
    --BookingLight: #bbbbbb;
    --BookingGray: #727272;
    --BookingGold: #d8a06c;
    --BookingGoldDark: #b17b48;
    --BookingGoldDarker: #9c693a;
    --BookingDanger: rgb(212, 51, 51);
    --BookingDangerLowOp: rgba(212, 51, 51, 0.1);
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    color: var(--BookingText);
    background-color: var(--BookingMain);
}

button {
    &[disabled="true"] {
        cursor: inherit!important;
    }
    &.booking-btn {
        font-family: 'Montserrat'!important;
        font-weight: 600!important;
        text-transform: initial!important;
        padding: 0.55rem 1.5rem!important;
        border: 1px solid transparent;
        border-radius: 5px!important;
        &.gold {
            background-color: var(--BookingGoldDark)!important;
            &:hover {
                background-color: var(--BookingGoldDarker)!important;
            }
        }
        &.reversed {
            background-color: transparent;
            border: 1px solid var(--BookingGoldDark);
            color: var(--BookingGoldDark);
            &:hover {
                color: var(--BookingGoldDarker);
                border: 1px solid var(--BookingGoldDarker);
            }
        }
    }
    &.disabled-btn {
        font-family: 'Montserrat'!important;
        font-weight: 600!important;
        text-transform: initial!important;
        padding: 0.65rem 1rem!important;
        background: none;
        color: var(--BookingText);
        border-radius: 6px!important;
        &:hover {
            background-color: var(--BookingBorderColor);
        }
        span {
            font-size: 1.4rem;
            margin-right: 5px;
        }
    }
    &.with-icon {
        display: flex;
        align-items: center;
        ion-icon {
            font-size: 1.35rem;
            margin-right: 8px;
        }
    }
    &.secondary {
        font-family: 'Montserrat'!important;
        font-weight: 600!important;
        text-transform: initial!important;
        padding: 0.65rem 1rem!important;
        background-color: var(--BookingBorderColor);
        color: var(--BookingText);
        border-radius: 8px!important;
        &:hover {
            background-color: var(--BookingGray);
        }
        span {
            font-size: 1.4rem;
            margin-right: 5px;
        }
    }
}


.input-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    label {
        font-size: 0.85rem;
        font-weight: 600;
        color: var(--BookingLight);
        width: 100%;
        text-align: left;
        margin-bottom: 5px;
    }
    input, select, textarea {
        width: 100%;
        padding: 0.55rem 1rem;
        border: var(--BookingBorder);
        transition: border-color 400ms ease;
        background-color: var(--BookingMain);
        color: var(--BookingText);
        font-size: 0.95rem;
        font-weight: 500;
        border-radius: 5px;
        outline: none;
        &:focus {
            border-color: var(--BookingGoldDarker);
            box-shadow: 0 0 1px var(--BookingGoldDarker);
        }
    }
    &.error {
        input, select, textarea {
            border-color: var(--BookingDanger)   
        }
    }
    .note {
        width: 100%;
        margin-top: 3px;
        text-align: right;
        font-size: 0.8rem;
        font-weight: 500;
        color: var(--BookingText);
        &.error {
            text-align: left;
            color: var(--BookingDanger);
        }
    }
}


.error-note {
    width: 100%;
    background-color: var(--BookingDangerLowOp);
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius:8px;
    margin-bottom: 0.75rem;
    font-size: 0.9rem;
    p {
        color: var(--BookingDanger);
        font-weight: 600;
    }
}

.main-note {
    position: fixed;
    left: 0.5rem;
    bottom: 0.25rem;
    z-index: 5000;
    font-size: 0.7rem;
    font-weight: 600;
    &.invert {
        color: var(--BookingMain)
    }
}


a {
    color: var(--BookingGold);
    transition: color 400ms ease;
    &:hover {
        color: var(--BookingGoldDark)
    }
}


.included {
    ul {
        list-style-position: inside;
        margin-bottom: 1.25rem;
    }
}