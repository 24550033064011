@import 'breakpoints';

.main-content {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 119px;
    .container {
        color: var(--BookingText);
        background-color: var(--BookingDark);
        padding: 2.25rem 1rem 15rem;
        border-radius: 15px;
        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.5);
        text-align: center;
        position: absolute;
        top: 50%;
        left: 80%;
        width: 400px;
        opacity: 0;
        transform: translate(-50%, -50%);
        visibility: hidden;
        transition: visibility 900ms,
                    left 800ms cubic-bezier(0.59, 0, 0.06, 1),
                    opacity 500ms cubic-bezier(0.59, 0, 0.06, 1);
        &.full-screen {
            width: 100%;
            height: 100%;
            box-shadow: none;
            overflow-y: auto;
            border-radius: 0;
            margin-top: 0;
        }
        &.slide-in {
            visibility: visible;
            opacity: 1;
            left: 50%;
            z-index: 1000;
        }
        &.passed {
            visibility: hidden;
            opacity: 0;
            left: 20%;
        }
        h1 {
            margin-bottom: 1.2rem;
        }
        .title {
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: 0.5rem;
        }
        .sub-title {
            display: flex;
            justify-content: center;
            margin-bottom: 2rem;
            button {
                color: var(--BookingGray);
                border-radius: 10px;
                padding: 0.5rem 0.8rem;
                font-size: 0.9rem;
                display: flex;
                align-items: center;
                justify-content: center;
                ion-icon {
                    font-size: 1rem;
                    margin-right: 8px;
                    margin-top: -1px;
                }
                &:hover {
                    color: var(--BookingText)
                }
            }
        }
        @include tabletNotDesktop {
            width: 70%;
        }
        @include mobile {
            width: 95%;
        }
    }
}

.bg-img {
    position: fixed;
    top: 0; left:0; right: 0; bottom: 0;
    z-index: 1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .booking-bg-effect {
        position: absolute;
        inset: 0;
        z-index: 2;
        background-color:rgba(0, 0, 0, 0.3)
    }
    .black {
        position: absolute;
        inset: 0;
        z-index: 3;
        background-color: var(--BookingDark);
        opacity: 0;
        transition: 500ms ease;
        &.visible {
            opacity: 1;
        }
    }
}